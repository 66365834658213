import * as React from 'react';
import Grid from 'styleguide/components/Grid/Grid';
import Span from 'styleguide/components/Span/Span';
import A from 'styleguide/components/Links/A';
import { PurchasedShipment } from 'api/orders/types';
import DateTimeZone from 'styleguide/components/DateTimeZone/DateTimeZone';

interface Props {
  purchasedShipment: PurchasedShipment;
  index: number;
}

const PurchasedShipmentItem = ({ purchasedShipment, index }: Props) => (
  <>
    <Grid.Row className="mt-5 mb-1">
      <Grid.Col>
        <DateTimeZone
          date={purchasedShipment.deliveryDate}
          className="paragraph-bold-mobile"
          fnsFormat="MMMM do yyyy, h:mma"
          preText={`Shipment ${index + 1}: Delivery by`}
        />
      </Grid.Col>
    </Grid.Row>
    <Grid.Row className="border max-w-[600px] p-4 border-solid border-[#ddd]">
      <Grid.Col>
        {purchasedShipment.packages.map((elem, i) => (
          <Grid.Row key={i}>
            <Grid.Col sm={6}>
              <Span>Package {i + 1}: </Span>
            </Grid.Col>
            <Grid.Col sm={6}>
              <A color="blue" underline="always" href={elem.trackingNumberUrl} targetBlank>
                {elem.trackingNumber}
              </A>
            </Grid.Col>
          </Grid.Row>
        ))}
      </Grid.Col>
    </Grid.Row>
  </>
);

export default PurchasedShipmentItem;
